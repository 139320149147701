.loader::after,
.loader::before {
  content: "";
  @apply box-border absolute top-0 left-0 border-4 rounded-full border-mariner-700;
  animation: animloader 1.5s linear infinite;
}
.loader::after {
  animation-delay: 0.75s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
