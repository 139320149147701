@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-VariableFont_slnt\,wght.ttf");
  font-weight: 100 900;
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: #fff;
}

#root {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  @apply bg-slate-200;
}

::-webkit-scrollbar-thumb {
  @apply bg-mariner-500;
  border-radius: 0.5rem;
  border: 0.3rem solid transparent;
  background-clip: content-box;
}

h2 {
  @apply mb-10 font-bold text-[22.13px] lg:text-[2.03rem];
}

h3 {
  @apply text-[19.44px] font-semibold leading-tight lg:text-[1.422rem];
}

h4 {
  @apply text-[18.22px] lg:text-[1.405rem];
}

h5 {
  @apply text-[18.22px] lg:text-[1.155rem];
}

h6 {
  @apply text-[14.95px] lg:text-[1.1rem] font-semibold;
}

a {
  @apply text-sm duration-150 ease-linear cursor-pointer;
}

p {
  @apply text-sm sm:text-[1.0165rem] leading-normal font-medium;
}

small {
  @apply block text-[12.64px] tracking-wide uppercase;
}

hr {
  background-image: linear-gradient(
    90deg,
    rgba(27, 132, 255, 1) 0%,
    rgb(1, 103, 225, 0) 100%
  );
  @apply opacity-100 h-[1.5px] text-transparent border-0;
}

input,
select,
textarea {
  @apply block w-full p-2 text-sm transition-all duration-300 ease-linear border-2 rounded-md outline-none focus:border-mariner-500;
}

select {
  @apply py-2.5 px-1;
}

select,
select option {
  color: #000000;
}

select:invalid,
select option[value=""] {
  color: #999999;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: inset 0 0 0 30px #fff;
}

.courses-container .swiper-wrapper {
  padding-bottom: 60px;
}

.swiper-container-horizontal > .swiper-pagination {
  @apply absolute bottom-0;
}

.swiper-pagination-bullet {
  @apply !w-4 !h-4 !opacity-70 !bg-mariner-300 !rounded-sm duration-150 ease-in;
}

.swiper-pagination-bullet-active {
  @apply !w-10 !bg-mariner-600 !opacity-100;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  @apply !text-2xl hidden lg:block;
}

.swiper-custom-arrows {
  @apply absolute bottom-[-15%] bg-mariner-600 rounded-lg z-30 hidden duration-200 ease-in-out text-white w-8 h-8 opacity-50 hover:opacity-100 lg:block;
}
.swiper-custom-arrow-right {
  @apply right-[45%] xl:right-[46.5%];
}
.swiper-custom-arrow-left {
  @apply left-[45%] xl:left-[46.5%];
}

.bg-mariner {
  background: linear-gradient(
    135deg,
    rgba(31, 166, 255, 1) 0%,
    rgba(1, 103, 225, 1) 70%,
    rgba(8, 90, 197, 1) 100%
  );
}

.background-filter-primary {
  @apply bg-[#003e8595] h-full;
}

.section-padding {
  @apply py-24;
}

nav {
  z-index: 99;
}

nav > div > a {
  @apply flex items-center gap-2 text-[1.0165rem] font-semibold;
}

nav .active {
  @apply p-2 bg-white text-mariner-500 rounded-l-md lg:p-0 lg:rounded-none;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.btn {
  @apply min-w-[120px] p-2 border-2 rounded-sm font-medium tracking-wide duration-300 ease-in-out text-sm xl:min-w-[140px];
}

.btn-outline {
  @apply text-white bg-transparent border-white hover:bg-white hover:text-black;
}

.btn-primary {
  @apply text-white border-transparent bg-mariner-500 hover:bg-mariner-600 disabled:bg-mariner-400;
}

.btn-primary-outline {
  @apply bg-transparent text-mariner-500 hover:text-white border-mariner-500 hover:bg-mariner-500 disabled:bg-mariner-400;
}

.video-modal-filter {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.video-modal {
  @apply h-[90vh] bg-cover bg-[25%] bg-fixed duration-300;
}

li {
  list-style: square;
}

.steps-shape {
  overflow: hidden;
  position: relative;
}
.steps-shape::before {
  content: "";
  font-family: "shape divider from ShapeDividers.com";
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw;
  background-size: 105px 100%;
  background-position: 0% 50%;
  transform: rotateX(180deg);
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.25 381" preserveAspectRatio="none"><g fill="%231b84ff"><path d="M8.47 0l33.86 381H0V0z"/><path d="M33.87 0l25.4 381H0V0z" opacity=".33"/><path d="M59.27 0l17.98 381H0V0z" opacity=".33"/></g></svg>');
}

.donation-shape {
  overflow: hidden;
  position: relative;
}
.donation-shape::before {
  content: "";
  font-family: "shape divider from ShapeDividers.com";
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 210% 65px;
  background-position: 0% 100%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" fill="%231b84ff"/></svg>');
}

/*@media (min-width: 768px) {
  .donation-shape::before {
    background-size: 210% 130px;
    background-position: 0% 100%;
  }
}*/

@media (min-width: 768px) {
  .donation-shape::before {
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw;
    background-size: 120px 311%;
    background-position: 100% 0%;
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.17 35.28" preserveAspectRatio="none"><path d="M1.16 0c-.8 3.17.4 7.29.56 10.04C1.89 12.8.25 19.3.42 22.71c.16 3.43.84 4.65.86 7.05.03 2.4-.88 5.52-.88 5.52h1.77V0z" fill="%231b84ff"/></svg>');
    transition: all 800ms ease-in-out;
  }
}

@media (min-width: 992px) {
  .donation-wrapper:hover .donation-shape::before {
    background-position: 100% 100%;
  }
}

footer small {
  @apply mb-5 sm:mb-5;
}

.banner-shape {
  overflow: hidden;
  position: relative;
}
.banner-shape::before {
  content: "";
  font-family: "shape divider from ShapeDividers.com";
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 100% 75px;
  background-position: 50% 100%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.278 3.52" preserveAspectRatio="none"><path d="M0 3.528S27.04 3.352 35.278 0v3.528z" opacity=".2" fill="%23ffffff"/><path d="M0 3.528S27.04 3.374 35.278.425v3.103z" opacity=".2" fill="%23ffffff"/><path d="M0 3.528S27.04 3.396 35.278.882v2.646z" fill="%23ffffff"/><path d="M0 3.527h35.278v.092H0z" fill="%23ffffff"/></svg>');
}

@media (min-width: 768px) {
  .banner-shape::before {
    background-size: 100% 140px;
    background-position: 50% 100%;
  }
}

@media (min-width: 1025px) {
  .banner-shape::before {
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw;
    background-size: 100% 140px;
    background-position: 50% 100%;
  }
}
@media (min-width: 2100px) {
  .banner-shape::before {
    background-size: 100% calc(2vw + 140px);
  }
}

.contact-section {
  @apply h-full max-w-full;
}

.contact-info {
  @apply absolute top-[40px] w-[350px] py-[40px] px-[20px] gap-[30px];
  height: calc(100% - 80px);
}

.contact-info div {
  @apply flex items-start gap-x-[15px];
}

.contact-form {
  @apply relative px-[50px] py-[80px] pl-[250px] ml-[150px];
  width: calc(100% - 160px);
}

@media (max-width: 992px) {
  .contact-info {
    @apply relative top-0 w-full h-full rounded-none;
  }

  .contact-form {
    @apply w-full py-[40px] px-[20px] m-0 rounded-none;
  }
}

.classes-table thead th {
  @apply p-4 text-sm font-medium text-left;
}

.classes-table tbody td {
  @apply p-4 text-sm font-medium text-left text-gray-700;
}

table td:first-child {
  @apply text-center;
}

.body-filter {
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
}

.sidebar-nav .active {
  @apply rounded-md bg-mariner-500;
}

.testimonials .swiper-3d .swiper-slide-shadow-left,
.testimonials .swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.testimonials .swiper-slide {
  box-shadow: 0 5px 50px rgba(6, 53, 131, 0.2);
}

.testimonials .swiper-slide-active {
  @apply !bg-white;
}

.testimonials .swiper-slide-active div {
  @apply !blur-none;
}

main {
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.custom-input-box input:focus ~ label {
  @apply translate-y-[-10px] translate-x-2 bg-white py-0 text-mariner-600;
}
